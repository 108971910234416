import * as React from "react"
import { Typography, Grid } from "@material-ui/core"

import blueSmallTriangleImg from "../../../../media/blue-small-triangle.png"

import { dataManagementConsultingServicesList } from "./data"
import { useStyles } from "./styles"

const title = "Data Management Consulting Services"

const DataManagementConsultingServices = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.innerWrapper}>
        <Typography variant="subtitle1">
          {title}
        </Typography>
        <Grid container spacing={10} className={classes.cardListWrapper}>
        {dataManagementConsultingServicesList.map((lItem, lIndex) => (
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}
            key={`data-management-consulting-service-item-${lIndex}`}
            className={classes.cardItem}
          >
            <div className={classes.cardItemInnerWrapper}>
              <img src={blueSmallTriangleImg} className={classes.cardItemTagImage} />
              <Typography variant="subtitle2" className={classes.cardItemTitle}>
                {`${lItem.title}`}
              </Typography>
              <Typography variant="body2" className={classes.cardItemDescription}>
                {lItem.description}
              </Typography>
            </div>
          </Grid>
        ))}
        </Grid>
      </div>
    </div>
  )
}

export default DataManagementConsultingServices
