export const dataManagementConsultingServicesList = [
  {
    title: "Data Strategy",
    description: "With the growing demand for transparency from investors and regulators, our most innovative clients are seeking ways to leverage their data to create new revenue streams and provide value-add services to end consumers."
  },
  {
    title: "Data Governance",
    description: "We help our clients stand up a governance framework for stakeholders to set enterprise policy standards related to the definition, ownership, quality, timing, and use of data across the organization. By performing advanced data analysis to quantify the impact of such decisions, we quickly build consensus for large, complex organizations."
  },
  {
    title: "Data Spend Analysis",
    description: "Whether buying data from a vendor or creating it internally, data is expensive and requires both technical and operational oversight. We help our clients reduce data spend by eliminating redundant processes and optimizing the use of their available data sets across the organization."
  },
  {
    title: "Reporting Strategy and Design",
    description: "The distribution of information through reports, dashboards, and data files can quickly become unwieldy if a proper framework is not in place to ensure certain standards are met. We help our clients scale by defining and implementing common data sets, standard report templates, and flexible dashboards."
  }
]
