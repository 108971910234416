import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    padding: "0 60px",
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  innerWrapper: {
    width: "100%",
    maxWidth: 1440,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  listWrapper: {
    marginTop: 40,
  },
  item: {
    width: "100%",
    marginBottom: 20,
    borderRadius: "0 !important",
    boxShadow: "none",
  },
  itemInnerWrapper: {
    position: "relative",
    height: "100%",
    minHeight: 150,
    backgroundColor: "#f0f0f0",
    padding: 40,
  },
  cardItemTagImage: {
    position: "absolute",
    top: 0,
    right: 0,
    width: "30%",
  },
  itemTitle: {
    //maxWidth: 300,
    //color: "#2b67b1",
    textAlign: "center",
    lineHeight: 1.2,
    marginBottom: 30,
  },
  itemDescription: {
    textAlign: "left",
  },
  itemLink: {
    color: "#27aae1",
    textDecoration: "none",
    //textTransform: "uppercase",
    textAlign: "center",
    marginTop: 20,
  },
}))
