import * as React from "react"
import { Link } from "@reach/router"
import { Grid, Typography } from "@material-ui/core"

import blueSmallTriangleImg from "../../../../media/blue-small-triangle.png"

import { dataAndAnalyticsServicesList } from "./data"
import { useStyles } from "./styles"

const DataAndAnaylticsServices = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.innerWrapper}>
        <Grid container spacing={10} className={classes.listWrapper}>
        {dataAndAnalyticsServicesList.map((item, index) => (
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}
            key={`data-and-analytics-service-item-${index}`}
            className={classes.item}
          >
            <div className={classes.itemInnerWrapper}>
            <img src={blueSmallTriangleImg} className={classes.cardItemTagImage} />
              <Typography variant="subtitle2" className={classes.itemTitle}>
                {item.title}
              </Typography>
              <Typography variant="body2" className={classes.itemDescription}>
                {item.description}
              </Typography>
              {item.link &&
                <Link
                  to={item.link.to}
                  className={classes.itemLink}
                >
                  <Typography variant="body2">
                    {`${item.link.title} >`}
                  </Typography>
                </Link>
              }
            </div>
          </Grid>
        ))}
        </Grid>
      </div>
    </div>
  )
}

export default DataAndAnaylticsServices
