export const dataAndAnalyticsServicesList = [
  {
    title: "Data Management Consulting Services",
    description: "We know how important a single, consistent, high-quality set of data is to your business. We help clients implement controlled frameworks to ensure data is on time, accurate, and available in usable formats."
  },
  {
    title: "Managed Data Services",
    description: "With our Rivvit data management engine, we provide a fully outsourced, cloud-based data warehouse and reporting solution that includes set up and oversight of incoming data feeds, configuration of business rules, and development of custom dashboards and reports.",
    link: {
      title: "Learn More",
      to: "technology"
    }
  }
]
