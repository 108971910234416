import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    //backgroundColor: "#f0f0f0",
    padding: 60,
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  innerWrapper: {
    width: "100%",
    maxWidth: 1440,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  serviceItem: {
    
  },
  itemTitle: {
    textAlign: "center",
  },
  itemDescription: {
    textAlign: "center",
  },
  cardListWrapper: {
    marginTop: 40,
  },
  cardItem: {
    width: "100%",
    marginBottom: 20,
    borderRadius: "0 !important",
    boxShadow: "none",
    
  },
  cardItemInnerWrapper: {
    position: "relative",
    height: "100%",
    minHeight: 150,
    backgroundColor: "#f0f0f0",
    padding: 40,
  },
  cardItemTagImage: {
    position: "absolute",
    top: 0,
    right: 0,
    width: "30%",
  },
  cardItemTitle: {
    //color: "#2b67b1",
    textAlign: "center",
    marginBottom: 15
  },
  cardItemDescription: {
    textAlign: "left",
    fontSize: "1.25rem",
    lineHeight: "1.75rem"
  },
}))
