import * as React from "react"
import { PageProps } from "gatsby"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import SubHero from "../../components/SubHero"
import Introduction from "../../components/Introduction"
import DataAndAnaylticsServices from "../../sections/services/DataAndAnalyticsServices"
import DataManagementConsultingServices from "../../sections/services/DataManagementConsultingServices"
import LearnHow from "../../components/LearnHow"
import NextService from "../../sections/services/NextService"

import dataAndAnalyticsBgUrl from "../../../media/data-and-analytics-services-banner-overlay.jpg"

const dataAndAnalyticsTitle = "Data and Analytics Services"
const dataAndAnalyticsDescription = ""
const introductionTitle = "Generate insightful information to make critical business decisions with confidence"
const intoroductionPara = "From data and analytics strategy and design to managed data services, Grandview is all about giving financial institutions the data they need to manage investments, mitigate risks, measure performance, and make informed business decisions quickly."
const para = [
  intoroductionPara
]
const learnHowTitle = "Read the case study"
const learnHowDescription = "Learn how multi-national insurance company enhanced portfolio risk and analytics capabilities for portfolio managers."
const learnHowLink = "case-study/portfolio-risk-and-analytics"

const DataAndAnaylticsPage = ({ location }: PageProps) => (
  <Layout location={location}>
    <SEO title={dataAndAnalyticsTitle} />
    <SubHero
      backgroundImage={dataAndAnalyticsBgUrl}
      title={dataAndAnalyticsTitle}
      description={dataAndAnalyticsDescription}
    />
    <Introduction
      title={introductionTitle}
      introduction={para}
    />
    <DataAndAnaylticsServices />
    <DataManagementConsultingServices />
    <LearnHow
      title={learnHowTitle}
      description={learnHowDescription}
      to={learnHowLink}
    />
    <NextService
      text="Next, learn about our managed data and reporting platform, Rivvit"
      link="technology"
    />
  </Layout>
)

export default DataAndAnaylticsPage
